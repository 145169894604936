import { ContentFrameEventMessageArgumentMap } from '../../foreground/types/events';
import PortalGate from '../../PortalGate';
import { CanvasImageHighlight } from '../../quoteshots/CanvasImageGenerator';
import { AnyDocument, Category, LenientReadingPosition } from '../../types';
import { QuoteshotFont, ThemeVariant } from '../../types/quoteshots';
import type { DocumentTag } from '../../types/tags';
import { Theme } from '../css/baseStyles';
import type { ScrollingManager } from './ScrollingManager';

export interface InitContentFrameProps {
  docId: string;
  initialReadPosition: LenientReadingPosition;
  initialScrollPosition: LenientReadingPosition;
  os: 'ios' | 'android';
  tags: DocumentTag[];
  title: string;
  category: AnyDocument['category'];
  userEmail: string;
  isPaginatedMode: boolean;
  deviceWidth: number;
  currentTheme: Theme;
  isDigestView: boolean;
  arePaginationAnimationsDisabled: boolean;
  isOriginalEmailView: boolean;
}

export interface MobileContentFrameWindow extends Window {
  pagination?: {
    enabled: boolean;
    smoothAnimationsDisabled: boolean;
    leftClickAreaWidth: number;
    rightClickAreaWidth: number;
    hapticsOnScrollEnabled: boolean;
    computedValues?: {
      windowWidth: number;
      numberOfColumns: number;
      contentWidth: number;
      lastChild: Element;
      lastChildX: number;
    };
  };
  theme: Theme;
  scrollingManager?: ScrollingManager;
  s?: ScrollingManager;
  documentLoaded?: boolean;
  deviceWidth?: number;
  isAutoHighlightingEnabled?: boolean;
  initContentFrame?: (props: InitContentFrameProps) => void;
  initCanvasWebview?: () => void;
  osType: 'ios' | 'android';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  portalGateToForeground: PortalGate<any, ContentFrameEventMessageArgumentMap>;

  // Methods
  titleClicked: () => void;
  ttsButtonClicked: () => void;
  reloadImagesThatFailedToLoad: () => void;
  drawHighlightOnCanvas?: (
    highlight: CanvasImageHighlight,
    theme: ThemeVariant,
    width: number,
    aspectRatio: number,
    darkMode: boolean,
    currentFont: QuoteshotFont,
  ) => Promise<void>;
  onFaviconError: () => void;
  onFaviconLoaded: () => void;
  onHeaderImageLoaded: () => void;
  onHeaderImageError: () => void;
  onSecondaryHeaderImageLoaded: () => void;
  onSecondaryHeaderImageError: () => void;
  setContent: (content: string, category: Category) => void;
}

export const CLICKABLE_TAGS = new Set(['A', 'FIGURE', 'IMG', 'VIDEO', 'RW-HIGHLIGHT', 'SVG']);
export const CLICKABLE_TAGS_THROUGH_PAGINATION_MARGINS = new Set(['A', 'RW-HIGHLIGHT']);

export const PAGINATION_DOCUMENT_TOP_MARGIN = 1000;
